<template>
  <div>
    <v-bottom-navigation app color="#00999a" grow>

      <v-btn :to="{ name: 'admin_business_edit_products', params: { slug: $route.params.slug } }">
        <span>Adicionar</span>
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'admin_business_list_products', params: { slug: $route.params.slug } }" exact>
        <span>Productos</span>
        <v-icon>mdi-tag-multiple-outline</v-icon>
      </v-btn>

      <v-btn :to="{ name: 'admin_business_edit_business', params: { slug: $route.params.slug }}" exact>
        <span>Mi negocio</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>

      <!-- <v-btn color="white">
        <span>Pedido</span>
        <v-badge color="red" content="2" overlap>
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn> -->

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "MenuApp",
  data: () => ({
    value: 'products',
    dialog: false,
  }),
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: inherit;
}
</style>
